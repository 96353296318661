import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef, useState } from "react"
import Button from "../components/Button"
import Heading from "../components/Heading"
import Root from "../components/Root"
import SEO from "../components/SEO"
import * as styles from "./index.module.scss"
import { CSSTransition } from "react-transition-group"

export const query = graphql`
  query {
    portraitImage: file(relativePath: { eq: "portrait.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 70
          height: 70
          placeholder: NONE
          transformOptions: { grayscale: true }
        )
      }
    }
  }
`

const Page = ({ data, uri }) => {
  const { portraitImage } = data

  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)

  useEffect(() => {
    document.body.style.setProperty(
      "--windowInnerHeight",
      window.innerHeight + "px"
    )
    setInProp(true)
  }, [])

  return (
    <Root>
      <SEO uri={uri} />
      <div className={styles.container}>
        <CSSTransition
          nodeRef={nodeRef}
          in={inProp}
          timeout={200}
          classNames={{
            enterDone: styles.cardTransition_enterDone,
          }}
        >
          <div ref={nodeRef} className={styles.cardTransition}>
            <div className={styles.card}>
              <div className={styles.header}>
                <div className={styles.portrait}>
                  <GatsbyImage
                    image={portraitImage.childImageSharp.gatsbyImageData}
                    alt=""
                  />
                </div>
                <div className={styles.profile}>
                  <Heading appearance={2}>murata_s</Heading>
                  <p>
                    My name is Satoshi Murata. I’m a designer from Kyoto, Japan.
                    I design and develop digital products and services.
                  </p>
                </div>
              </div>
              <div className={styles.body}>
                <ul>
                  <li>
                    <Button to="https://hearable.jp/">Hearable Inc.</Button>
                  </li>
                  <li>
                    <Button to="https://noti.co.jp/">Noti Inc.</Button>
                  </li>
                  <li>
                    <Button to="https://twitter.com/murata_s">Twitter</Button>
                  </li>
                  <li>
                    <Button to="https://github.com/muratas">GitHub</Button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </Root>
  )
}

export default Page
