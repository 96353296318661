import React, { ReactNode, useEffect } from "react"
import * as styles from "./Button.module.scss"

interface Props {
  to: string
  children: ReactNode
}

const Button: React.FC<Props> = ({ to, children }) => {
  return (
    <a href={to} target="_blank" rel="noopener" className={styles.button}>
      {children}
    </a>
  )
}

export default Button
